<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('prices')"
                        :isColumns="true"
                        :isNewButton="checkPermission('productprice_store')"
                        @new-button-click="add"
                        :isImportButton="true"
                        @import-button-click="$bvModal.show('importFormModal')"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('prices')"
                              :isColumns="true"
                              :isNewButton="checkPermission('productprice_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()" :export-excel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="6" md="4" xl="3" xs="12">
                        <b-form-group :label="$t('product')">
                            <payment-products-selectbox v-model="datatable.queryParams.filter.product_id">
                            </payment-products-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" xl="3" xs="12">
                        <b-form-group :label="$t('period')">
                            <payment-periods-selectbox v-model="datatable.queryParams.filter.period_id">
                            </payment-periods-selectbox>
                        </b-form-group>
                    </b-col>

<!--                    <b-col sm="6" md="4" xl="3" xs="12">-->
<!--                        <b-form-group :label="$t('customer_group')">-->
<!--                            <b-form-select v-model="datatable.queryParams.filter.customer_group"-->
<!--                                           :options="options_customer_group"></b-form-select>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->

                    <b-col sm="6" md="4" xl="3" xs="12">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.registration_academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col xs="12" xl="12" md="12">
                        <b-row>
                            <b-col xl="6" xs="12">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox
                                        v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                                </b-form-group>
                            </b-col>

                            <b-col xl="6" xs="12">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                       v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6" md="4" xl="3" xs="12">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="datatable.queryParams.filter.start_date"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3" xs="12">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="datatable.queryParams.filter.end_date"/>
                        </b-form-group>
                    </b-col>


                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal ref="modal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? 'add' : 'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="product_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('product')">
                                                <payment-products-selectbox v-model="form.product_id">
                                                </payment-products-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="period_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('period')">
                                                <payment-periods-selectbox v-model="form.period_id">
                                                </payment-periods-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="faculty_code" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox v-model="form.faculty_code">
                                                </faculty-selectbox>
                                            </b-form-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                            </b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="program_code" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox :faculty_code="form.faculty_code"
                                                                   v-model="form.program_code">
                                                </program-selectbox>
                                            </b-form-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                            </b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="registration_academic_year" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('registration_academic_year')">
                                                <academic-years-selectbox v-model="form.registration_academic_year">
                                                </academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="customer_group" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('customer_group')">
                                                <parameter-selectbox v-model="form.customer_group"
                                                               :code="'customer_groups'"></parameter-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('currency')">
                                                <b-form-select v-model="form.currency" :options="options_currency">
                                                </b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="price" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('price')">
                                                <b-form-input type="number" v-model="form.price">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="number_of_installments" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('number_of_installments')">
                                                <b-form-input type="number" v-model="form.number_of_installments">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('due_date')">
                                                <select-date v-model="form.due_date" :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="installment_start_date" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('installment_start_date')">
                                                <select-date v-model="form.installment_start_date"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="start_date" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('start_date')">
                                                <select-date v-model="form.start_date" :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-4 col-xs-12">
                                        <ValidationProvider name="end_date" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('end_date')">
                                                <select-date v-model="form.end_date" :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t('save') | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="importFormModal" size="xl" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="importForm">
                        <b-row>
                            <b-col rows="12" md="6" lg="6">
                                <ValidationProvider name="period_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('period')">
                                        <payment-periods-selectbox
                                            :validate-error="errors[0]"
                                            v-model="importForm.period_id">
                                        </payment-periods-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col rows="12" md="6" lg="6">
                                <ValidationProvider name="product_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('product')">
                                        <payment-products-selectbox
                                            :validate-error="errors[0]"
                                            v-model="importForm.product_id">
                                        </payment-products-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col rows="12" md="6" lg="6">
                                <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('excel_file')">
                                        <div class="d-flex custom-file-upload">
                                            <b-form-file
                                                v-model="importForm.file"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('select_file')"
                                                :drop-placeholder="$t('drop_file')"
                                                ref="fileInput"
                                                multiple></b-form-file>
                                            <b-button variant="outline-secondary"
                                                      @click="$refs.fileInput.$el.childNodes[0].click();">
                                                {{ $t('browse') }}
                                            </b-button>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <b-form-group :label="$t('excel_format')">
                                    <div class="alert alert-info">
                                        {{ $t('product_prices_import_format') }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <div class="d-flex justify-content-center mb-5">
                                    <b-button variant="primary" @click="upload">
                                        {{ $t('import') }}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import qs from 'qs'
import PriceService from "@/services/PriceService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentProductsSelectbox from '../../../components/interactive-fields/PaymentProductsSelectbox.vue';
import PaymentPeriodsSelectbox from '../../../components/interactive-fields/PaymentPeriodsSelectbox.vue';
import AcademicYearsSelectbox from '../../../components/interactive-fields/AcademicYearsSelectbox.vue';
import DatatableFilter from "@/components/datatable/DatatableFilter";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import SelectDate from "@/components/interactive-fields/SelectDate";

export default {
    components: {
        SelectDate,
        FacultySelectbox,
        ProgramSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        PaymentProductsSelectbox,
        PaymentPeriodsSelectbox,
        AcademicYearsSelectbox,
        DatatableFilter
    },
    metaInfo() {
        return {
            title: this.$t('prices')
        }
    },
    data() {
        return {
            id: 0,
            form: {},
            importForm: {},
            options_currency: [
                {value: 'TRY', text: this.$t('TRY')},
                {value: 'USD', text: this.$t('USD')},
                {value: "EUR", text: this.$t('EUR')},
            ],
            options_customer_group: [
                {value: 'NON-EU', text: this.$t('NON-EU')},
                {value: 'EU', text: this.$t('EU')},
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'productprice_update',
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'productprice_delete',
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.toUpperCase('product'),
                        field: 'product_name',
                        sortable: false,
                        hidden: false
                    },

                    {
                        label: this.toUpperCase('period'),
                        field: 'period_code',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('program_code')),
                        field: 'program_code',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('program'),
                        field: 'program_name',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('price'),
                        field: 'price',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('currency')),
                        field: 'currency',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('customer_group')),
                        field: 'customer_group',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('registration_academic_year')),
                        field: 'registration_academic_year',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('start_date')),
                        field: 'start_date',
                        sortable: true,
                        hidden: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('end_date')),
                        field: 'end_date',
                        sortable: true,
                        hidden: false
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        callService(config) {
            return PriceService.getAll(config)
        },
        loadData() {
            if (this.id > 0) {
                PriceService.get(this.id)
                    .then(response => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                let formData = {...this.form}
                this.setBooleans(formData);
                if (this.id == 0) {
                    PriceService.store(formData, this.value)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                } else {
                    PriceService.update(this.id, formData)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                }

            }
        },
        delete(id) {
            this.deleteModal(() => {
                PriceService.deletePrices(id)
                    .then(response => {
                        this.filter();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
        async upload() {
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('product_id', this.importForm.product_id);
                formData.append('period_id', this.importForm.period_id);
                formData.append('file', this.importForm.file[0]);
                PriceService.fileImport(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.importForm);
                    })
            }
        }
    },
};
</script>

